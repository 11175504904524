<template>
  <b-container class="text-center vehicle-details">
    <h2 class="text-primary title">{{ $t("listing.label.details") }}</h2>

    <div class="mt-4">
      <TheListingCard />
    </div>

    <b-link v-b-modal.notYourVehicle class="not-your-vehicle">{{ $t("ListingProcess.notYourVehicle") }}</b-link>

    <b-card v-show="errorMessage" class="my-5 text-danger">
      {{ errorMessage }}
    </b-card>

    <div>
      <div class="text-left">
        <section v-if="styles" class="mb-5">
          <h6 class="border-bottom py-3">{{ $t("ListingProcess.Styles") }}</h6>
          <b-btn class="m-1" v-for="(t, key) in styles" :key="key" :disabled="loading">
            <b-form-checkbox
              :disabled="loading"
              :unchecked-value="null"
              v-model="style"
              :value="t.ListingTags[0].Value"
              @change="selectStyle(t.StyleId)"
            >
              {{ t.ListingTags[0].Value }}
            </b-form-checkbox>
          </b-btn>
        </section>
        <!-- <section v-else>
          <h6 class="border-bottom py-3">Style: {{ style }}</h6>
        </section> -->

        <b-overlay :show="loading" opacity="0.9" variant="white">
          <div v-if="style">
            <section class="listing-details-section">
              <h6 class="section-title">
                {{ $t("ListingProcess.NumberOfKeys") }}
                <span v-if="vehicleNumberOfKeys">: {{ vehicleNumberOfKeys }}</span>
              </h6>
              <!-- <b-form-checkbox-group
            v-model="nofk"
          >
            <b-btn class="m-1" v-for="(p, key) in nofkList" :key="key">
              <b-form-checkbox
                :value="p.value"
                >{{ p.text }}</b-form-checkbox
              >
            </b-btn>
          </b-form-checkbox-group> -->
              <!-- <p>{{nofkList}}</p> -->
              <div class="section-item-wrapper">
                <b-form-checkbox
                  v-model="nofk"
                  button
                  v-for="(p, key) in nofkList"
                  :key="key"
                  :value="p.Value"
                  button-variant="outline-primary"
                  @change="
                    saveInput('vehicleNumberOfKeys', nofk ? p.Value : null),
                      setListing(13, p.TagValueId, p.ListingTagId)
                  "
                >
                  <b-icon-check v-if="nofk === p.Value" variant="white" />
                  <span class="px-1">{{ p.Value }}</span>
                </b-form-checkbox>
              </div>
            </section>

            <section class="listing-details-section" v-show="!$store.getters.hasSeatInfo">
              <h6 class="section-title">
                {{ $t("ListingProcess.NumberOfSeats") }}<span v-if="vehicleSeats">: {{ vehicleSeats }}</span>
              </h6>

              <div class="section-item-wrapper">
                <b-form-checkbox
                  v-model="seats"
                  button
                  v-for="(p, key) in seatsList"
                  :key="key"
                  :value="p.Value"
                  button-variant="outline-primary"
                  @change="
                    saveInput('vehicleSeats', seats ? p.Value : null),
                      setListing(7, p.Value, p.ListingTagId)
                  "
                  ><b-icon-check v-if="seats === p.Value" variant="white" />
                  <span class="px-1">{{ p.text }}</span>
                </b-form-checkbox>
              </div>
            </section>

            <section class="listing-details-section" v-show="!$store.getters.hasDoorInfo">
              <h6 class="border-bottom py-3">
                {{ $t("ListingProcess.NumberOfDoors") }}<span v-if="vehicleDoors">: {{ vehicleDoors }}</span>
              </h6>
              <b-form-checkbox
                v-model="doors"
                button
                v-for="(p, key) in doorsList"
                :key="key"
                :value="p.Value"
                class="mx-1"
                button-variant="outline-primary"
                @change="
                  saveInput('vehicleDoors', doors ? p.Value : null),
                    setListing(6, p.Value, p.ListingTagId)
                "
                ><b-icon-check v-if="doors === p.Value" variant="white" />
                <span class="px-1">{{ p.text }}</span>
              </b-form-checkbox>
            </section>

            <section class="listing-details-section">
              <h6 class="section-title">
                {{ $t("ListingProcess.ExternalColour") }}<span v-if="vehicleColor">: {{ vehicleColor }}</span>
              </h6>
              <div v-if="!isExternalColorExpand">
                <div class="section-item-wrapper">
                  <div class="section-item-wrapper color">
                    <div
                      v-for="(c, key) in externalColors"
                      :key="key"
                      class="d-inline-block"
                    >
                      <b-btn
                        :class="c.Code !== '#FFFFFF' ? '' : 'blue-border'"
                        style="width: 80px; height: 40px"
                        :style="{
                          background: c.Code ? c.Code : 'transparent',
                          borderColor: c.code !== '#FFFFFF' ? 'transparent' : '',
                        }"
                        @click="
                          (externalColor = externalColor === c.Value ? null : c.Value),
                            setListing(8, c.TagValueId, c.ListingTagId),
                            saveInput('vehicleColor', externalColor ? c.Value : null)
                        "
                        ><b-icon-check
                          v-show="externalColor === c.Value"
                          scale="1.5"
                          :variant="c.Code !== '#FFFFFF' ? 'white' : 'primary'"
                        />
                      </b-btn>
                      <p
                        class="color-name"
                        :class="{
                          'text-primary': externalColor === c.Value,
                        }"
                      >
                        {{ c.Value }}
                      </p>
                    </div>
                  </div>

                  <b-btn
                    class="more-color"
                    variant="transparent"
                    @click="addToShowMoreList('externalColor')"
                    ><b-link>{{ $t("ListingProcess.SeeMoreColor") }} <b-icon-chevron-right /></b-link>
                  </b-btn>
                </div>
              </div>
              <div v-else class="section-item-wrapper">
                <div v-for="(c, key) in genericColors" :key="key" class="d-inline-block">
                  <b-btn
                    :class="c.Code !== '#FFFFFF' ? '' : 'blue-border'"
                    style="width: 80px; height: 40px"
                    :style="{
                      background: c.Code ? c.Code : 'transparent',
                      borderColor: c.code !== '#FFFFFF' ? 'transparent' : '',
                    }"
                    @click="
                      (externalColor = externalColor === c.Value ? null : c.Value),
                        setListing(8, c.TagValueId, c.ListingTagId),
                        saveInput('vehicleColor', externalColor ? c.Value : null)
                    "
                    ><b-icon-check
                      v-show="externalColor === c.Value"
                      scale="1.5"
                      :variant="c.Code !== '#FFFFFF' ? 'white' : 'primary'"
                    />
                  </b-btn>
                  <p
                    class="color-name"
                    :class="{
                      'text-primary': externalColor === c.Value,
                    }"
                  >
                    {{ c.Value }}
                  </p>
                </div>
              </div>
            </section>

            <section class="listing-details-section" v-show="bodyTypes">
              <h6 class="section-title">
                {{ $t("ListingProcess.BodyType") }}<span v-if="vehicleBodyType">: {{ vehicleBodyType }}</span>
              </h6>
              <div class="section-item-wrapper">
                <b-form-checkbox
                  v-model="bodyType"
                  button
                  v-for="(b, key) in bodyTypes"
                  :key="key"
                  :value="b.TagValueId"
                  button-variant="outline-primary"
                  @change="
                    saveInput('vehicleBodyType', bodyType ? b.Value : null),
                      setListing(9, b.TagValueId, b.ListingTagId)
                  "
                  ><b-icon-check v-if="bodyType === b.TagValueId" variant="white" />
                  <span class="px-1">{{ b.Value }}</span>
                </b-form-checkbox>
              </div>
            </section>

            <section class="listing-details-section" v-show="transmissionsTypes">
              <h6 class="section-title">
                {{ $t("ListingProcess.TransmissionsType") }}<span v-if="vehicleTransmission">: {{ vehicleTransmission }}</span>
              </h6>
              <div class="section-item-wrapper">
                <b-form-checkbox
                  v-model="transmissionsType"
                  button
                  v-for="(t, key) in transmissionsTypes"
                  :key="key"
                  :value="t.TagValueId"
                  button-variant="outline-primary"
                  @change="
                    saveInput('vehicleTransmission', transmissionsType ? t.Value : null),
                      setListing(21, t.TagValueId, t.ListingTagId)
                  "
                  ><b-icon-check
                    v-if="transmissionsType === t.TagValueId"
                    variant="white"
                  />
                  <span class="px-1">{{ t.Value }}</span>
                </b-form-checkbox>
              </div>
            </section>

            <section class="listing-details-section" v-show="fuels">
              <h6 class="section-title">
                {{ $t("ListingProcess.FuelType") }}<span v-if="vehicleFuelType">: {{ vehicleFuelType }}</span>
              </h6>
              <div class="section-item-wrapper">
                <div class="section-item-wrapper">
                  <template v-for="(f, key) in fuels">
                    <b-form-checkbox
                      :key="key"
                      v-if="!isFuelsExpand && key <= fuelslength"
                      v-model="fuel"
                      button
                      :value="f.TagValueId"
                      button-variant="outline-primary"
                      @change="
                        saveInput('vehicleFuelType', fuel ? f.Value : null),
                          setListing(18, f.TagValueId, f.ListingTagId)
                      "
                      ><b-icon-check v-if="fuel === f.TagValueId" variant="white" />
                      <span class="px-1">{{ f.Value }}</span>
                    </b-form-checkbox>
                    <b-form-checkbox
                      :key="key + 'fuel'"
                      v-if="isFuelsExpand"
                      v-model="fuel"
                      button
                      :value="f.TagValueId"
                      button-variant="outline-primary"
                      @change="
                        saveInput('vehicleFuelType', fuel ? f.Value : null),
                          setListing(18, f.TagValueId, f.ListingTagId)
                      "
                      ><b-icon-check v-if="fuel === f.TagValueId" variant="white" />
                      <span class="px-1">{{ f.Value }}</span>
                    </b-form-checkbox>
                  </template>

                  <b-form-checkbox
                    button
                    class="more-color"
                    v-if="!isFuelsExpand"
                    @change="(fuelslength = fuels.length), addToShowMoreList('fuels')"
                  >
                    {{ $t("carDetail.label.showMore") }}
                    <b-icon-chevron-right/>
                  </b-form-checkbox>
                </div>
              </div>
            </section>

            <section class="listing-details-section" v-show="cylinders">
              <h6 class="section-title">
                {{ $t("ListingProcess.EngineNumberCylinder") }}<span v-if="vehicleEngine"
                  >: {{ vehicleEngine }}</span
                >
              </h6>
              <div class="section-item-wrapper">
                <b-form-checkbox
                  v-model="cylinder"
                  button
                  v-for="(c, key) in cylinders"
                  :key="key"
                  :value="c.TagValueId"
                  button-variant="outline-primary"
                  @change="
                    saveInput('vehicleEngine', cylinder ? c.Value : null),
                      setListing(24, c.TagValueId, c.ListingTagId)
                  "
                >
                  <b-icon-check v-if="cylinder === c.TagValueId" variant="white" />
                  <span class="px-1">{{ c.Value }}</span>
                </b-form-checkbox>
              </div>
            </section>

            <section class="listing-details-section" v-show="displacements">
              <h6 class="section-title">
                {{ $t("ListingProcess.EngineDisplacement") }}
                <span v-if="vehicleEngineDisplacement">: {{ vehicleEngineDisplacement }}</span>
              </h6>
              <div class="section-item-wrapper">
                <b-form-checkbox
                  v-model="displacement"
                  button
                  v-for="(d, key) in displacements"
                  :key="key"
                  :value="d.TagValueId"
                  button-variant="outline-primary"
                  @change="
                    saveInput('vehicleEngineDisplacement', displacement ? d.Value : null),
                      setListing(25, d.TagValueId, d.ListingTagId)
                  "
                  ><b-icon-check v-if="displacement === d.TagValueId" variant="white" />
                  <span class="px-1">{{ d.Value }}</span>
                </b-form-checkbox>
              </div>
            </section>

            <section class="listing-details-section" v-show="drivetrains">
              <h6 class="section-title">
                {{ $t("ListingProcess.Drivetrain") }}<span v-if="vehicleDrivetrain">: {{ vehicleDrivetrain }}</span>
              </h6>
              <div class="section-item-wrapper">
                <b-form-checkbox
                  v-model="drivetrain"
                  button
                  v-for="(d, key) in drivetrains"
                  :key="key"
                  :value="d.TagValueId"
                  button-variant="outline-primary"
                  @change="
                    saveInput('vehicleDrivetrain', drivetrain ? d.Value : null),
                      setListing(20, d.TagValueId, d.ListingTagId)
                  "
                  ><b-icon-check v-if="drivetrain === d.TagValueId" variant="white" />
                  <span class="px-1">{{ d.Value }}</span>
                </b-form-checkbox>
              </div>
            </section>
          </div>
        </b-overlay>
      </div>
    </div>
    <b-modal
      id="fullFeatures"
      hide-header
      hide-footer
      centered
      no-close-on-backdrop
      size="lg"
    >
      <template #default="{ hide }">
        <div>
          <b-button class="close p-2 m-2" @click="hide(), submitFeature()">
            <b-icon icon="x" scale="1.5"/>
          </b-button>
        </div>
        <div class="p-4">
          <h2>{{ $t("ListingProcess.StandardFeatures") }}</h2>
          <p class="text-half-dark">Actual features may differ</p>
          <div v-for="(sf, key) in standardFeatures" :key="key">
            <h3 class="border-bottom pt-2">{{ sf.Name }}</h3>
            <div v-for="(sft, key) in sf.Tags" :key="key" class="px-3">
              <div v-show="sft.IsRequired">
                <b-form-checkbox
                  :checked="sft.TagValues[0].Installed"
                  @change="setFeature(sft.TagId, sft.TagValues[0].Installed)"
                  >{{ sft.TagName }}
                </b-form-checkbox>
              </div>
            </div>
            <div v-for="(sftc, key) in sf.TagCategories" :key="key" class="px-3">
              <h4 class="border-bottom">{{ sftc.Name }}</h4>
              <div v-for="(sftct, key) in sftc.Tags" :key="key" class="px-3">
                <div v-show="sftct.IsRequired">
                  <b-form-checkbox
                    :checked="sftct.TagValues[0].Installed"
                    @change="setFeature(sft.TagId, sft.TagValues[0].Installed)"
                    >{{ sftct.TagName }}
                    <!-- <span class="text-half-dark">{{ sftct }}</span> -->
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="m-auto text-center">
          <b-button
            pill
            size="md"
            variant="primary"
            class="px-3 my-3"
            @click="submitFeature(), hide()"
          >
            {{ $t("ListingProcess.CloseAndSubmit") }}
          </b-button>
        </div>
      </template>
    </b-modal>
    <div class="d-flex justify-content-between align-items-center mt-10">
      <div class="d-flex justify-content-between align-items-center">
        <b-button pill variant="half-dark" class="px-3" v-b-modal.cancelModal>
          <v-icon>mdi-close</v-icon> {{ $t("global.button.cancel") }}
        </b-button>
      </div>
        <TheSaveAndLeave />
      </div>
  </b-container>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  props: {
    restart: { type: Function },
    nextButton: { type: Function },
    styleList: { type: Array },
  },
  components: {
    TheListingCard: () => import("@/components/listing/TheListingCard"),
    TheSaveAndLeave: () => import("@/components/listing/TheSaveAndLeaveButton"),
  },
  data() {
    return {
      loading: false,
      nofk: this.$store.getters.vehicleNumberOfKeys,
      nofkList: null,
      seats: this.$store.getters.vehicleSeats,
      seatsList: [
        { text: "2", value: "2" },
        { text: "4", value: "4" },
        { text: "5", value: "5" },
        { text: "6", value: "6" },
        { text: "7", value: "7" },
        { text: "9", value: "9" },
      ],
      doors: this.vehicleDoors,
      doorsList: [
        { text: "2", value: "2" },
        { text: "3", value: "3" },
        { text: "4", value: "4" },
        { text: "6", value: "6" },
      ],
      styles: null,
      style: this.$store.getters.vehicleStyle,
      externalColors: null,
      externalColor: this.$store.getters.vehicleColor,
      genericColors: null,
      trim: null,
      fuels: null,
      fuel: null,
      fuelslength: 4,
      drivetrains: null,
      drivetrain: null,
      transmissionsTypes: null,
      transmissionsType: null,
      bodyTypes: null,
      bodyType: null,
      cylinders: null,
      cylinder: null,
      displacements: null,
      displacement: null,
      standardFeatures: null,
      selectedFeature: [],
      tags: [],
      carInfo: null,
      color18: [
        { name: "Black", code: "#000000" },
        { name: "White", code: "#FFFFFF" },
        { name: "Red", code: "#FF0000" },
        { name: "Yellow", code: "#FFFF00" },
        { name: "Blue", code: "#0000FF" },
        { name: "Green", code: "#008000" },
        { name: "Silver", code: "#C0C0C0" },
        { name: "Gold", code: "#FFD700" },
        { name: "Brown", code: "#A52A2A" },
        { name: "Orange", code: "#FFA500" },
        { name: "Purple", code: "#800080" },
        { name: "Tan", code: "#D2B48C" },
        { name: "Maroon", code: "#800000" },
        { name: "Teal", code: "#008080" },
        { name: "Gray", code: "#808080" },
        { name: "Copper", code: "#B87333" },
        { name: "Beige", code: "#F5F5DC" },
      ],
      errorMessage: null,
    };
  },
  computed: {
    ...mapGetters([
      "vehicleStyle",
      "vehicleStyleId",
      "vehicleBodyType",
      "vehicleEngine",
      "vehicleEngineDisplacement",
      "vehicleDrivetrain",
      "vehicleTransmission",
      "vehicleFuelType",
      "vehicleDoors",
      "vehicleSeats",
      "vehicleNumberOfKeys",
      "vehicleColor",
      "detailPageShowMore",
    ]),
    isExternalColorExpand() {
      return this.detailPageShowMore.includes("externalColor");
    },
    isFuelsExpand() {
      return this.detailPageShowMore.includes("fuels");
    },
  },
  methods: {
    //JHMCG56492C003897 trim:s package:s
    //2FMDK4KC4CBA27842 trim:s package:m
    //JN1EV7AR5JM611460 trim:m package:m
    showStyles() {
      let l = this.styleList[0];
      if (this.styleList.length === 1) {
        l.ListingTags.forEach((element) => {
          if (element.TagId === 389) this.style = element.Value;
        });
        // this.getCarInfo(l.StyleId);
        this.selectStyle(l.StyleId);
      } else if (this.styleList.length > 1) {
        this.styles = this.styleList;
      }
    },
    selectStyle(id) {
      this.$store.dispatch("vehicleStyle", this.style);
      this.getCarInfo(id);
      this.$store.dispatch("vehicleStyleId", id);
    },
    getColors(colorList) {
      this.genericColors = colorList;
      // colorList.forEach(element => {
      //   this.color18.forEach(color => {
      //     if (element.Value === color.name) {
      //       element.Code = color.code;
      //     }
      //   });
      // });
      this.genericColors = colorList;
      colorList = colorList.filter(function (el) {
        return el.Installed;
      });
      this.externalColors = colorList;
    },
    saveInput(item, value) {
      this.$store.dispatch(item, value);
    },
    addToShowMoreList(value) {
      let array = this.detailPageShowMore;
      array.push(value);
      this.$store.dispatch("detailPageShowMore", array);
    },
    checkNextButton() {
      if (
        this.vehicleNumberOfKeys &&
        this.vehicleSeats &&
        this.vehicleDoors &&
        this.vehicleColor &&
        this.vehicleBodyType &&
        this.vehicleTransmission &&
        this.vehicleFuelType &&
        this.vehicleEngine &&
        this.vehicleEngineDisplacement &&
        this.vehicleDrivetrain
      ) {
        this.nextButton("page1", false);
      } else {
        this.nextButton("page1", true);
      }
    },
    async getCarInfo(id) {
      this.loading = true;
      this.errorMessage = null;
      let response;
      try {
        if (this.$store.getters.listingStockID) {
          response = await axios.post("Listing/CarInfo", {
            ListingId: this.$store.getters.listingStockID,
          });
        } else {
          response = await axios.post("Listing/CarInfo", {
            VIN: this.$store.getters.vin,
            StyleId: id,
            planId: this.$store.getters.listingPlanID,
          });
        }
        this.carInfo = response.data;
        this.$store.dispatch("listingStockID", this.carInfo.ListingId);
        this.carInfo.TagCategories.forEach((element) => {
          element.Tags.forEach((el) => {
            if (el.TagId === 8) {
              this.getColors(el.TagValues);
            }
            if (el.TagId === 13) {
              this.nofkList = el.TagValues;
            }
            if (el.TagId === 9) {
              this.$store.dispatch("vehicleBodyType", null);
              if (el.TagValues.length > 1) {
                this.bodyTypes = el.TagValues;
              }
              el.TagValues.forEach((e) => {
                if (e.Installed) {
                  this.bodyType = e.TagValueId;
                  this.$store.dispatch("vehicleBodyType", e.Value);
                }
              });
            }
            if (el.TagId === 18) {
              this.$store.dispatch("vehicleFuelType", null);
              if (el.TagValues.length > 1) {
                this.fuels = el.TagValues;
              }
              el.TagValues.forEach((e) => {
                if (e.Installed) {
                  this.fuel = e.TagValueId;
                  this.$store.dispatch("vehicleFuelType", e.Value);
                }
              });
            }
            if (el.TagId === 20) {
              this.$store.dispatch("vehicleDrivetrain", null);
              if (el.TagValues.length > 1) {
                this.drivetrains = el.TagValues;
              }
              el.TagValues.forEach((e) => {
                if (e.Installed) {
                  this.drivetrain = e.TagValueId;
                  this.$store.dispatch("vehicleDrivetrain", e.Value);
                }
              });
            }
            if (el.TagId === 21) {
              this.$store.dispatch("vehicleTransmission", null);
              if (el.TagValues.length > 1) {
                this.transmissionsTypes = el.TagValues;
              }
              el.TagValues.forEach((e) => {
                if (e.Installed) {
                  this.transmissionsType = e.TagValueId;
                  this.$store.dispatch("vehicleTransmission", e.Value);
                }
              });
            }
            if (el.TagId === 24) {
              this.$store.dispatch("vehicleEngine", null);
              if (el.TagValues.length > 1) {
                this.cylinders = el.TagValues;
              }
              el.TagValues.forEach((e) => {
                if (e.Installed) {
                  this.cylinder = e.TagValueId;
                  this.$store.dispatch("vehicleEngine", e.Value);
                }
              });
            }
            if (el.TagId === 25) {
              this.$store.dispatch("vehicleEngineDisplacement", null);
              if (el.TagValues.length > 1) {
                this.displacements = el.TagValues;
              }
              el.TagValues.forEach((e) => {
                if (e.Installed) {
                  this.displacement = e.TagValueId;
                  this.$store.dispatch("vehicleEngineDisplacement", e.Value);
                }
              });
            }
            if (el.TagId === 389) {
              this.$store.dispatch("vehicleTrim", null);
              el.TagValues.forEach((e) => {
                if (e.Installed) {
                  this.trim = e.TagValueId;
                  this.$store.dispatch("vehicleTrim", e.Value);
                }
              });
            }
          });
          if (element.Id === 4) {
            this.standardFeatures = element.TagCategories;
          }
        });
        this.loading = false;
        this.styles = null;
      } catch (error) {
        this.errorMessage = error;
        // this.loading = false;
      }
    },
    setListing(tagId, value, listingTagId) {
      let v = value.toString();
      this.tags.push({
        TagId: tagId,
        ListingTagId: listingTagId,
        Value: v,
      });
    },
    async sendListingChange() {
      let mileage;
      if (this.$store.getters.mileageUnit === "mi") {
        mileage = this.$store.getters.vehicleMileage * 1.609344;
      } else {
        mileage = this.$store.getters.vehicleMileage;
      }
      mileage = mileage.toString();
      this.tags.push({
        TagId: 11,
        Value: mileage,
      });
      try {
        await axios.post("Listing/SetListing", {
          ListingId: this.$store.getters.listingStockID,
          Tags: this.tags,
        });
      } catch (error) {
        console.log(error);
      }
    },
    setFeature(tagId, value) {
      let feature,
        existed = false;
      if (this.selectedFeature.length > 0) {
        this.selectedFeature.forEach((element) => {
          if (element.TagId === tagId) {
            existed = true;
            if (element.Value === "True") element.Value = "False";
            else if (element.Value === "False") element.Value = "True";
          }
        });
      }
      if (!existed) {
        if (value) {
          feature = { TagId: tagId, Value: "False" };
        } else {
          feature = { TagId: tagId, Value: "True" };
        }
        this.selectedFeature.push(feature);
      }
    },
    async submitFeature() {
      if (this.selectedFeature.length > 0) {
        try {
          await axios.post("Listing/SetListing", {
            ListingId: this.$store.getters.listingStockID,
            Tags: this.selectedFeature,
          });
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
  beforeMount() {
    if (this.vehicleStyle) {
      this.getCarInfo(this.vehicleStyleId);
    } else {
      this.showStyles();
    }
  },
  updated() {
    this.checkNextButton();
  },
  beforeDestroy() {
    this.sendListingChange();
  },
};
</script>

<style lang="scss">


.cancelButton {
  width: 188px;
  height: 56px;
  background: #acacac;
  color: #111;
  font-size: 20px !important;
  font-weight: 600 !important;
  font-family: "Inter" !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vehicle-details {
  
  .title {
    font-weight: 600;
    font-size: 24px !important;
    margin-bottom: 16px;
  }
  .not-your-vehicle {
    font-size: 14px;
  }
}
.listing-details-section {
  margin-top: 64px;
  .section-title {
    font-size: 20px;
    font-weight: 600;
    font-family: "Inter" !important;
    margin-bottom: 16px;
    border-bottom: 1px solid #d0d0d0;
    padding-bottom: 8px;
  }
  .section-item-wrapper {
    display: flex;
    align-items: flex-start;
    column-gap: 24px;
    row-gap: 16px;
    flex-wrap: wrap;
    &.color {
      column-gap: 16px;
    }
    button {
      border-radius: 4px;
      overflow: hidden;
    }
  }
  .btn-group-toggle {
    label {
      height: 40px;
      font-size: 14px;
      font-weight: 400;
      font-family: "Inter", sans-serif !important;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 90px;
      box-shadow: none;
      transition: all 0.3s;
      &.active,
      &:active,
      &:focus,
      &:hover {
        color: white !important;
        span {
          color: white !important;
        }
      }
      svg {
        font-size: 30px !important;
        height: 30px;
        width: 30px;
      }
    }
  }
  .color-name {
    font-weight: 500;
    font-size: 14px;
    margin-top: 8px;
    margin-bottom: 0;
  }
  .blue-border {
    border: 1px solid $light-blue !important;
  }
  .more-color {
    height: 40px;
    vertical-align: top;
    font-size: 14px;
    min-width: 170px;
    font-weight: 500;
    border: 1px solid #dbdbdb;
    color: $light-blue;
    border-radius: 4px;
    font-family: "Inter", sans-serif;
    .b-icon {
      font-size: 125% !important;
      width: auto !important;
      height: auto !important;
    }
    &:hover {
      color: #004077 !important;
    }
    label {
      background: none !important;
      border: none !important;
      font-size: 14px;
      font-weight: 500;
      color: $light-blue;
      font-family: "Inter", sans-serif;
      &:hover {
        color: #004077 !important;
      }
    }
  }
}
</style>
